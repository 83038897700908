import { useLayoutEffect, useMemo, useState } from 'react';
import Confetti from 'react-confetti';
import { useLocation, useNavigate } from 'react-router-dom';

import { Stack } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import BreadCrumbs from '../../components/common/BreadCrumbs';
import Tabs from '../../components/common/Tabs';
import { useAuth } from '../../contexts/auth';
import { useSnackbar } from '../../contexts/snackbar';
import RoutePaths from '../../routes/RoutePaths';
import { EventAction, EventCategory, logEvent } from '../../services/analytics';
import { BillingKeys } from '../../services/billing';
import { deleteUserProfile } from '../../services/user';
import BillingSection from './components/BillingSection';
import DeleteAccount from './components/DeleteAccount';

// TODO: generalize Confetti
const ConfettiColors = ['#00B050', '#007435', '#F4C5C8', '#228EF2', '#7030A0', '#D67319'];

/**
 * ProfileSettings component renders the settings page for user profiles.
 * It includes tabs for 'Account' and 'Billing' settings, and handles
 * navigation and state management for these sections.
 *
 * @component
 * @example
 * return (
 *   <ProfileSettings />
 * )
 *
 * @returns {JSX.Element} The rendered ProfileSettings component.
 *
 * @remarks
 * - Utilizes `useAuth` for authentication-related actions like logout.
 * - Uses `useSnackbar` to display notifications.
 * - Employs `useLocation` and `useNavigate` from `react-router-dom` for routing.
 * - Uses `useMemo` to memoize the tabs content and default selected tab value.
 * - Uses `useMutation` from `react-query` to handle profile deletion.
 *
 * @function
 * @name ProfileSettings
 */
const ProfileSettings = () => {
  const { logout } = useAuth();
  const { showSnackbar } = useSnackbar();
  const location = useLocation();
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const [confettiPieces, setConfettiPieces] = useState(0);

  const TabsContent = useMemo(
    () => [
      { value: 'account', label: 'Account', icon: 'fi fi-rr-user' },
      { value: 'billing', label: 'Billing', icon: 'fi fi-rr-credit-card' },
    ],
    [],
  );

  const BreadCrumbLinks = [
    {
      label: 'Settings',
      href: RoutePaths.SETTINGS,
    },
  ];

  const defaultSelectedTabValue = useMemo(() => {
    // check if the path is /settings/account or /settings/billing or /settings/
    const selectedTabvalue = location.pathname.split('/')[2];
    return selectedTabvalue !== '' ? selectedTabvalue : 'account';
  }, [location.pathname]);

  const { mutate: deleteProfile } = useMutation({
    mutationFn: deleteUserProfile,
    onSuccess: () => {
      logEvent(EventCategory.USER_INTERACTION, EventAction.CLICK, 'Delete Account started');
      logout();
      showSnackbar('success', 'Account Deleted');
    },
  });

  const updateLocationPath = (value: string) => {
    // update the browser path when the tab is changed
    navigate(`${RoutePaths.SETTINGS}/${value}`);
  };

  useLayoutEffect(() => {
    let timer: string | number | NodeJS.Timeout | undefined;
    // check if query params has action=success
    const queryParams = new URLSearchParams(location.search);
    const action = queryParams.get('action');

    if (action === 'success') {
      setConfettiPieces(200);
      queryClient.invalidateQueries([BillingKeys.SUBSCRIPTION_PLAN]);
      queryClient.invalidateQueries([BillingKeys.INVOICES]);
      // after 7 seconds, set confetti pieces to 0, so they can gracefully fall away
      // and new pieces are not generated
      timer = setTimeout(() => {
        setConfettiPieces(0);
        // remove the query params from the url
        navigate(RoutePaths.BILLING);
      }, 4000);
      showSnackbar('success', 'Upgraded to JobQuest Pro');
    }

    return () => clearTimeout(timer);
  }, [location.search, navigate, queryClient, showSnackbar]);

  return (
    <Stack>
      <Confetti
        numberOfPieces={confettiPieces}
        tweenDuration={500}
        colors={ConfettiColors}
        initialVelocityY={8}
        gravity={0.08}
      />
      <Stack
        gap={4}
        sx={{
          padding: '32px 80px',
        }}
      >
        <BreadCrumbs links={BreadCrumbLinks} />
        <Tabs
          tabs={TabsContent}
          defaultSelected={defaultSelectedTabValue}
          onChange={updateLocationPath}
        >
          <DeleteAccount onDelete={deleteProfile} />
          <BillingSection />
        </Tabs>
      </Stack>
    </Stack>
  );
};
export default ProfileSettings;
