import http from './http';
import { Storage } from './storage';

// login, takes user email and returns whether user exists or not and sets otp token cookie
export const login = async (email: string) => {
  if (!email) return Promise.reject(new Error('Email is required'));

  try {
    const res = await http.post('/users/login', { email });
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

// verify otp
export const verifyOtp = async (otp: string) => {
  if (!otp) return Promise.reject(new Error('OTP is required'));

  try {
    const res = await http.post(
      '/users/verify-login/',
      { otp },
      {
        headers: {
          Authorization: `Bearer ${Storage.getOtpToken()}`,
        },
      },
    );
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

export const refreshLogin = async () => {
  try {
    const res = await http.post('/users/refresh-token', null, {
      headers: {
        Authorization: `Bearer ${Storage.getRefreshToken()}`,
      },
    });
    if (
      res?.data.status === 'success' &&
      res?.data.data.access_token &&
      res?.data.data.refresh_token
    ) {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

// signup/register new user
export const signup = async (email: string, name?: string) => {
  if (!email) return Promise.reject(new Error('Email is required'));

  try {
    const res = await http.post('/users/register/', { email, name });
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

// resend otp
export const resendOtp = async () => {
  try {
    const res = await http.get('/users/resend-otp/');
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

export const hasJWTToken = () => !!Storage.getJwtToken();
