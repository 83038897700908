import { create } from 'zustand';

import { hasJWTToken } from '../services/auth';
import type { AccountStatus, FeatureFlags } from '../services/billing';
import { PlanTypes, getAccountStatus } from '../services/billing';

type AccountStatusState = {
  accountStatus: AccountStatus | null;
  setAccountStatus: (status: AccountStatus) => void;
  resetToDefault: () => void;
  initialize: () => Promise<void>;
  reInitialize: () => Promise<void>;
};

const DEFAULT_FEATURE_FLAGS: FeatureFlags = {
  IMPORT_RESUME: false,
  AI_CUSTOMIZE_RESUME: false,
  CREATE_JOB_MATCH_SCORE: false,
  GENERATE: false,
  COMPOSE: false,
  AUTOFIX: false,
  SUPERCHARGE: false,
  JD_IMPORT: false,
  SAMPLE_RESUME: false,
  BLANK_RESUME: false,
};

const DEFAULT_ACCOUNT_STATUS: AccountStatus = {
  id: '',
  subscription: PlanTypes.STANDARD,
  planEndData: '',
  forceRefreshToken: false,
  featureFlags: DEFAULT_FEATURE_FLAGS,
};

/**
 * A Zustand store for managing account status.
 *
 * @remarks
 * This store provides state management for account status, including setting the status,
 * resetting it to a default value, and initializing it by fetching from the server.
 * The store also provides a function to reinitialize the account status.
 *
 * @example
 * ```typescript
 * const { accountStatus, setAccountStatus, resetToDefault, initialize } = useAccountStatusStore();
 * ```
 *
 * @returns {object} The account status store with the following properties:
 * - `accountStatus`: The current account status.
 * - `setAccountStatus`: A function to set the account status.
 * - `resetToDefault`: A function to reset the account status to the default value.
 * - `initialize`: An async function to initialize the account status by fetching it from the server.
 * - `reInitialize`: An async function to reinitialize the account status.
 */
export const useAccountStatusStore = create<AccountStatusState>((set) => ({
  accountStatus: DEFAULT_ACCOUNT_STATUS,
  setAccountStatus: (status) => set(() => ({ accountStatus: status })),
  resetToDefault: () => set(() => ({ accountStatus: DEFAULT_ACCOUNT_STATUS })),
  initialize: async () => {
    if (!hasJWTToken()) {
      return;
    }

    // Fetch account status from the server
    try {
      const accountStatus = await getAccountStatus();
      set(() => ({ accountStatus }));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to fetch account status:', error);
    }
  },
  reInitialize: async (): Promise<void> => useAccountStatusStore.getState().initialize(),
}));
