import { useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Grid } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useToggle } from '@uidotdev/usehooks';

import LoadingSpinner from '../../../components/common/LoadingSpinner';
import { useSnackbar } from '../../../contexts/snackbar';
import RoutePaths from '../../../routes/RoutePaths';
import type { Receipt, SubscriptionPlan } from '../../../services/billing';
import {
  BillingKeys,
  PlanTypes,
  cancelSubscription,
  createSubscriptionSession,
  getCurrentSubscription,
  getInvoices,
  getManagePaymentMethodSession,
} from '../../../services/billing';
import { useAccountStatusStore } from '../../../stores/AccountStatusStore';
import { BillingFaqs } from './BillingFaqs';
import CurrentSubscription from './CurrentSubscription';
import ManageSubscriptionModal from './ManageSubcriptionModal/ManageSubcriptionModal';
import Receipts from './Receipts/Receipts';

/**
 * Renders the billing section of the profile settings page.
 *
 * @returns The JSX element representing the billing section.
 */
const BillingSection: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { showSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const location = useLocation();
  const navigate = useNavigate();

  const { initialize: initializeAccountStatusStore } = useAccountStatusStore();

  const [isLoading, toggleLoading] = useToggle(false);

  useLayoutEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const action = queryParams.get('action');

    if (action === 'upgrade') {
      setIsOpen(true);
      // remove the query params from the url
      navigate(RoutePaths.BILLING);
    }
  }, [location.search, navigate]);

  // get current subscription from billing service
  const { data: subscription } = useQuery<SubscriptionPlan>({
    queryKey: [BillingKeys.SUBSCRIPTION_PLAN],
    queryFn: getCurrentSubscription,
  });

  // get invoices from billing service
  const { data: invoices } = useQuery<Receipt[]>({
    queryKey: [BillingKeys.INVOICES],
    queryFn: getInvoices,
  });

  // create a subcription session mutation and redirect to the checkout page
  const { mutate: createSubscriptionSessionMutation } = useMutation({
    mutationFn: createSubscriptionSession,
    onSuccess: (data) => {
      window.location.href = data.url;
    },
    onError: () => {
      toggleLoading(false);
      showSnackbar(
        'info',
        'We encountered an issue processing your data. Please try resubmitting or contact support',
      );
    },
  });

  // create a cancel subscription mutation and show the snackbar message
  const { mutate: cancelSubscriptionMutation } = useMutation({
    mutationFn: cancelSubscription,
    onSuccess: () => {
      toggleLoading(false);
      showSnackbar('success', 'Your subscription has been successfully canceled.');
      queryClient.invalidateQueries([BillingKeys.SUBSCRIPTION_PLAN]);
      queryClient.invalidateQueries([BillingKeys.INVOICES]);
      initializeAccountStatusStore();
    },
    onError: () => {
      toggleLoading(false);
      showSnackbar(
        'error',
        'Failed to cancel your subscription. Please try again or contact support',
      );
    },
  });

  const onUpgradeDowngradeClick = () => {
    setIsOpen(true);
  };

  const onUpdateConfirm = (price_id: string) => {
    if (!price_id) return;

    setIsOpen(false);
    toggleLoading(true);
    // call the mutation to create a subscription session
    createSubscriptionSessionMutation(price_id);
  };

  const onDowngradeConfirm = (reason: string) => {
    if (!reason || !subscription) return;
    toggleLoading(true);
    setIsOpen(false);

    // call the mutation to cancel the subscription
    cancelSubscriptionMutation({
      subId: subscription.id,
      reason,
    });
  };

  const onManagePaymentMethodClick = () => {
    if (!subscription || subscription?.planName === PlanTypes.STANDARD) return;

    toggleLoading(true);
    // call the service method and open the payment method management page
    getManagePaymentMethodSession()
      .then((session) => {
        if (session?.url) {
          window.location.href = session.url;
        }
      })
      .catch(() => {
        toggleLoading(false);
        showSnackbar('error', 'Failed to load payment method management page. Please try again.');
      });
  };

  return (
    <Grid container width="100%">
      {isLoading && <LoadingSpinner />}
      <Grid item xs={12} md={7} paddingRight={8}>
        <CurrentSubscription
          subscriptionPlan={subscription}
          onUpgradeDowngradeClick={onUpgradeDowngradeClick}
          onManagePaymentMethodClick={onManagePaymentMethodClick}
        />
        <Receipts
          receipts={invoices}
          sx={{
            mt: 5,
          }}
        />
      </Grid>
      <Grid item xs={12} md={5}>
        <BillingFaqs />
      </Grid>

      <ManageSubscriptionModal
        isOpen={isOpen}
        subscription={subscription}
        onClose={() => setIsOpen(false)}
        onUpdateConfirm={onUpdateConfirm}
        onDowngradeConfirm={onDowngradeConfirm}
      />
    </Grid>
  );
};

export default BillingSection;
