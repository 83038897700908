import { useEffect, useState } from 'react';
import Confetti from 'react-confetti';

import { Box, Stack, Typography } from '@mui/material';

import List from '../../../components/List';
import Button from '../../../components/common/Button';
import Card from '../../../components/common/Card';
import Logo from '../../../components/common/Logo';
import { useAuth } from '../../../contexts/auth';

interface WelcomeProps {
  onNextClick: () => void;
}

// TODO: generalize Confetti
const ConfettiColors = ['#00B050', '#007435', '#F4C5C8', '#228EF2', '#7030A0', '#D67319'];

const Welcome = ({ onNextClick }: WelcomeProps) => {
  const { user } = useAuth();
  const onboardingInstructions = [
    'A job posting from LinkedIn, Google, or any job site',
    'Your resume in PDF format',
  ];

  const [confettiPieces, setConfettiPieces] = useState(0);

  const getUserName = () => {
    if (user?.name) {
      const userName = user.name.split(' ');
      if (userName.length > 2) {
        return `${userName[0]} ${userName[1]}`;
      }
      return userName[0];
    }
    return '';
  };

  useEffect(() => {
    setConfettiPieces(200);
    // after 7 seconds, set confetti pieces to 0, so they can gracefully fall away
    // and new pieces are not generated
    const timer = setTimeout(() => {
      setConfettiPieces(0);
    }, 4000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Box>
      <Confetti
        numberOfPieces={confettiPieces}
        tweenDuration={500}
        colors={ConfettiColors}
        initialVelocityY={8}
        gravity={0.08}
      />
      {/* TODO: move logo to main page */}
      <Logo height="50px" style={{ marginLeft: '2rem', marginTop: '2rem' }} />
      <Stack
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          minWidth: '40vw',
          marginTop: '2rem',
        }}
      >
        <Card
          sx={{ borderRadius: '12px', maxWidth: '650px', padding: 5 }}
          cardContentProps={{
            sx: {
              padding: 0,
              '&:last-child': {
                paddingBottom: 0,
              },
            },
          }}
        >
          <Stack sx={{ gap: 6, alignItems: 'center' }}>
            <Stack gap={4}>
              <Typography variant="h1" sx={{ textAlign: 'center' }}>
                Welcome to JobQuest{user?.name !== '' ? `, ${getUserName()}!` : ''}
              </Typography>
              <Stack gap={2.5}>
                <Typography variant="body3">
                  Let&apos;s create your first job match score report to identify gaps and optimize
                  your resume.
                </Typography>
                <Typography variant="body3">
                  To create the report, have the following 2 items ready:
                  <List
                    dataList={onboardingInstructions}
                    listItemProps={{ sx: { padding: 0.5 } }}
                    sx={{ marginLeft: 0.5, paddingBottom: 0 }}
                    listItemIconProps={{ sx: { marginTop: '6px' } }}
                    listItemTextProps={{ primaryTypographyProps: { variant: 'body3' } }}
                  />
                </Typography>
                <Typography variant="body3">
                  Rest assured, we don&apos;t share your resume or any private data.
                </Typography>
                <Typography variant="body3">
                  The entire process can take up to 3 minutes.
                </Typography>
              </Stack>
            </Stack>
            <Button onClick={onNextClick} disabled={confettiPieces > 0} sx={{ width: '80px' }}>
              Next
            </Button>
          </Stack>
        </Card>
      </Stack>
    </Box>
  );
};

export default Welcome;
