import FAQs from '../../../components/FAQs/FAQs';

export const BillingFaqs = () => {
  const links = [
    {
      label: 'Do I need to provide payment information to start a free trial?',
      href: '#',
    },
    {
      label: 'What does the JobQuest free trial include?',
      href: '#',
    },
    {
      label: 'What happens after the free trial ends?',
      href: '#',
    },
    {
      label: 'How is my payment information handled if I choose to upgrade?',
      href: '#',
    },
    {
      label: 'What payment method and currency do you support?',
      href: '#',
    },
    {
      label: 'How do I cancel my subscription?',
      href: '#',
    },
    {
      label: 'Who should I contact for any payment related questions and issues?',
      href: '#',
    },
  ];

  return <FAQs links={links} />;
};
