import { useState } from 'react';

import { Stack, Typography } from '@mui/material';

import Button from '../../../components/common/Button';
import Modal from '../../../components/common/Modal/Modal';
import ModalActionsDeprecated from '../../../components/common/Modal/ModalActions_deprecated';
import ModalDetail from '../../../components/common/Modal/ModalDetail';

interface DeleteAccountProps {
  onDelete: () => void;
}

/**
 * Component for deleting user account.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.onDelete - The function to be called when the account delete process is confirmed.
 * @returns {JSX.Element} The JSX element representing the delete account component.
 */
const DeleteAccount = ({ onDelete }: DeleteAccountProps) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <Stack gap="24px">
      <Modal open={showModal}>
        <>
          <ModalDetail
            title="Delete Account?"
            description="Are you sure you want to permanently delete your account and all the associated data? This action cannot be undone."
          />
          <ModalActionsDeprecated
            type="delete"
            onConfirm={onDelete}
            onClose={() => setShowModal(false)}
            disableConfirmButtonDuration={5}
          />
        </>
      </Modal>
      <Stack>
        <Typography variant="label1">Delete Account</Typography>
        <Typography variant="body2">
          Permanently delete your account and all data, including resumes.
        </Typography>
      </Stack>
      <Button
        color="error"
        sx={{ maxWidth: 'fit-content' }}
        size="small"
        onClick={() => setShowModal(true)}
      >
        Delete Account
      </Button>
    </Stack>
  );
};

export default DeleteAccount;
