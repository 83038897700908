import { useState } from 'react';

import {
  Box,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';

import Button from '../../../../components/common/Button';

interface DowngradeSubscriptionContentProps {
  onDowngradeConfirm?: (reason: string) => void;
  onback?: () => void;
}

const DowngradeReasons = [
  'I got a job/interview',
  'I updated my resume',
  'I’m not able to achieve my objective with JobQuest',
  'I don’t like the service',
  'Other',
];

/**
 * DowngradeSubscriptionContent component renders a modal content for downgrading a subscription.
 * It provides a list of reasons for downgrading and allows the user to select one.
 * The user can confirm the downgrade or go back to the previous step.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.onDowngradeConfirm - Callback function to handle the downgrade confirmation.
 * @param {Function} props.onback - Callback function to handle the back action.
 *
 * @returns {JSX.Element} The rendered component.
 */
const DowngradeSubscriptionContent = ({
  onDowngradeConfirm = () => {},
  onback,
}: DowngradeSubscriptionContentProps) => {
  const [selectedValue, setSelectedValue] = useState('0');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  return (
    <Stack gap={2}>
      <Stack gap={1}>
        <Typography variant="body3" color="text.primaryGray">
          Your payment will not be renewed after this payment cycle ends on 10th Sept, you will not
          lose any existing data.
        </Typography>
        <Typography variant="body3" color="text.primaryGray">
          Please let us know your reason for cancelling your JobQuest Pro subscription.
        </Typography>
      </Stack>

      {/* list with gray border and 4 list items with radio buttons, use Mui list component */}
      <Box
        sx={{
          border: '1px solid',
          borderColor: 'border.main',
          borderRadius: '8px',
        }}
      >
        <RadioGroup value={selectedValue} onChange={handleChange}>
          <List>
            {DowngradeReasons.map((reason, index) => (
              <ListItem
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                sx={{
                  padding: '0px 8px',
                  alignItems: 'flex-start',
                  ':hover': {
                    backgroundColor: 'primary.light',
                  },
                }}
                onClick={(e) => setSelectedValue((e.target as HTMLInputElement).value)}
              >
                <FormControlLabel
                  value={index}
                  control={<Radio />}
                  label={
                    <ListItemText
                      primary={reason}
                      sx={{ margin: 0 }}
                      primaryTypographyProps={{ variant: 'body3', sx: { wordBreak: 'break-word' } }}
                    />
                  }
                />
              </ListItem>
            ))}
          </List>
        </RadioGroup>
      </Box>

      <Stack
        sx={{
          flexDirection: 'row',
          justifyContent: 'flex-end',
          gap: 2,
        }}
      >
        <Button
          variant="text"
          onClick={onback}
          sx={{
            color: 'text.primary',
            ':hover': {
              backgroundColor: 'background.main',
            },
          }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          onClick={() => onDowngradeConfirm(DowngradeReasons[parseInt(selectedValue, 10)])}
        >
          Cancel Subscription
        </Button>
      </Stack>
    </Stack>
  );
};

export default DowngradeSubscriptionContent;
