import { Stack, Typography, useTheme } from '@mui/material';

import Icon from '../../../../components/common/Icon';
import type { Receipt } from '../../../../services/billing';
import { formatBillingDate } from '../../../../utils/billingUtils';

/**
 * Component to display the content of a receipt.
 *
 * @param {Object} props - The properties object.
 * @param {Receipt} props.receipt - The receipt object containing details like invoice date, paid date, and URL.
 * @param {string} props.key - The unique key for the receipt item.
 *
 * @returns {JSX.Element} The JSX element representing the receipt content.
 */
const ReceiptContent = ({ receipt, key }: { receipt: Receipt; key: string }) => {
  const { invoiceDate, paidDate, url } = receipt;
  const theme = useTheme();

  return (
    <Stack
      key={key}
      sx={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '20px 12px',
        borderBottom: '1px solid',
        borderColor: 'border.light',
        ':hover': {
          backgroundColor: 'background.mediumLight',
          '.hover-icon': {
            visibility: 'visible',
          },
        },
      }}
    >
      <Stack>
        <Typography variant="body3">Invoiced: {formatBillingDate(invoiceDate)}</Typography>
        <Typography variant="assistive" color="text.secondary">
          Paid: {formatBillingDate(paidDate)}
        </Typography>
      </Stack>
      <Stack
        className="hover-icon"
        sx={{
          height: '36px',
          width: '36px',
          minWidth: 0,
          padding: 0,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'background.main',
          borderRadius: '8px',
          cursor: 'pointer',
          visibility: 'hidden',
        }}
      >
        <a
          href={url}
          target="_blank"
          rel="noreferrer"
          style={{
            // remove all styles from anchor tag
            textDecoration: 'none',
            color: 'inherit',
          }}
        >
          <Icon
            className="fi fi-rr-arrow-up-right"
            fontSize="16px"
            style={{
              color: theme.palette.text.tertiary,
              cursor: 'pointer',
            }}
          />
        </a>
      </Stack>
    </Stack>
  );
};

export default ReceiptContent;
