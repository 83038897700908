import { Stack, Typography, useTheme } from '@mui/material';

import Button from '../../../../components/common/Button';
import Card from '../../../../components/common/Card';
import Icon from '../../../../components/common/Icon';
import { PlanTypes, type SubscriptionPlan } from '../../../../services/billing';
import { getPlanName } from '../../../../utils/billingUtils';

// Adjust the import path as needed

interface Feature {
  label: string;
  subText: string;
}

interface Plan {
  id: string;
  planName: string;
  amount: number;
  features: Feature[];
}

interface SubscriptionPlanCardProps {
  plan: Plan;
  subscription: SubscriptionPlan | undefined;
  onDowngradeClick: () => void;
  onUpdateConfirmClick: () => void;
}

/**
 * SubscriptionPlanCard component renders a card displaying subscription plan details.
 * It includes plan name, amount, features, and an action button to upgrade or downgrade the plan.
 *
 * @component
 * @param {SubscriptionPlanCardProps} props - The properties for the SubscriptionPlanCard component.
 * @param {Plan} props.plan - The subscription plan details.
 * @param {Subscription} [props.subscription] - The current subscription details, if any.
 * @param {Function} props.onDowngradeClick - Callback function to handle downgrade action.
 * @param {Function} props.onUpdateConfirmClick - Callback function to handle upgrade action.
 *
 * @returns {JSX.Element} The rendered SubscriptionPlanCard component.
 */
const SubscriptionPlanCard = ({
  plan,
  subscription,
  onDowngradeClick,
  onUpdateConfirmClick,
}: SubscriptionPlanCardProps) => {
  const theme = useTheme();

  const getButtonText = (planName: string) => {
    if (subscription && subscription?.planName === planName) {
      return 'Current Plan';
    }

    if (!subscription) {
      return planName === PlanTypes.PRO ? 'Upgrade' : 'Current Plan';
    }

    return subscription?.planName === PlanTypes.PRO ? 'Downgrade' : 'Upgrade';
  };

  const getButtonColor = (planName: string) => {
    if (planName === PlanTypes.PRO) {
      return subscription?.planName === PlanTypes.PRO ? 'border.light' : 'common.white';
    }

    return subscription?.planName === PlanTypes.PRO ? 'text.primary' : 'border.light';
  };

  const getButtonBgColor = (planName: string) => {
    if (planName === PlanTypes.PRO) {
      return subscription?.planName === PlanTypes.PRO ? 'common.white' : 'primary.main';
    }

    return 'common.white';
  };

  const getButtonBorderColor = (planName: string) => {
    if (planName === PlanTypes.PRO) {
      return subscription?.planName === PlanTypes.PRO ? 'transparent' : 'border.light';
    }

    return 'border.light';
  };

  return (
    <Card
      key={plan.id}
      sx={{
        borderRadius: 1.5,
        width: '100%',
        height: '100%',
      }}
    >
      <Stack>
        <Typography variant="body2">{getPlanName(plan.planName)}</Typography>
        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'flex-end',
          }}
        >
          <Typography variant="h1">${plan.amount}</Typography>
          <Typography variant="body3" color="text.primaryGray" paddingBottom={0.375}>
            /month
          </Typography>
        </Stack>
        <Button
          variant={
            plan.planName === PlanTypes.PRO && subscription?.planName !== PlanTypes.PRO
              ? 'contained'
              : 'outlined'
          }
          fullWidth
          sx={{
            mt: 1.5,
            mb: 1.5,
            color: getButtonColor(plan.planName),
            backgroundColor: getButtonBgColor(plan.planName),
            borderColor: getButtonBorderColor(plan.planName),
            borderWidth: '1x',
            ':hover': {
              borderColor: plan.planName !== PlanTypes.PRO ? 'border.light' : 'transparent',
              borderWidth: '1x',
              backgroundColor: plan.planName === PlanTypes.PRO ? 'common.white' : 'background.main',
            },
            '&.Mui-disabled': {
              backgroundColor: 'common.white !important',
            },
          }}
          disabled={plan.planName === subscription?.planName}
          onClick={plan.planName !== PlanTypes.PRO ? onDowngradeClick : onUpdateConfirmClick}
        >
          {/* {plan.planName !== PlanTypes.PRO ? 'Current Plan' : 'Upgrade'} */}
          {getButtonText(plan.planName)}
        </Button>

        {/* features */}
        <Stack gap={1.5}>
          <Typography variant="label2">
            {plan.planName !== PlanTypes.PRO ? 'Core features' : 'Everything in free, plus'}
          </Typography>
          {plan.features.map((feature) => (
            <Stack key={feature.label} direction="row" gap={1}>
              <Icon
                className="fi fi-br-check"
                fontSize="16px"
                style={{
                  color:
                    plan.planName !== PlanTypes.PRO
                      ? theme.palette.text.tertiary
                      : theme.palette.primary.main,
                }}
              />
              <Stack>
                <Typography variant="body3">{feature.label}</Typography>
                <Typography variant="assistive" color="text.primaryGray">
                  {feature.subText}
                </Typography>
              </Stack>
            </Stack>
          ))}

          {plan.planName === PlanTypes.PRO && (
            <Typography variant="assistive" color="text.tertiary" ml={2.8}>
              Billed every 10th of the month. Cancel anytime
            </Typography>
          )}
        </Stack>
      </Stack>
    </Card>
  );
};

export default SubscriptionPlanCard;
