import React, { useEffect, useLayoutEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import { ThemeProvider } from '@emotion/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import './App.css';
import CommonLayout from './components/Layout/common';
import { AuthProvider } from './contexts/auth';
import { SnackbarProvider } from './contexts/snackbar';
import RouteGuard from './routes/RouteGuard';
import RouteTracker from './routes/RouteTracker';
import { protectedRoutes, publicRoutes } from './routes/routes';
import { initGA, logException } from './services/analytics';
import { Storage, StorageKeys } from './services/storage';
import { useAccountStatusStore } from './stores/AccountStatusStore';
import { useLastVisitedTopPageStore } from './stores/LastVisitedTopPageStore';
import theme from './theme/theme';
import { isIOSDevice } from './utils';

const queryClient = new QueryClient();

const App = () => {
  const { initialize: initializeAccountStatusStore, reInitialize } = useAccountStatusStore();
  const { lastVisitedTopPage } = useLastVisitedTopPageStore();

  const shouldRefetchAccountStatus = Storage.getShouldRefetchAccountStatus();

  // initialize Google Analytics
  initGA();

  useLayoutEffect(() => {
    initializeAccountStatusStore();

    if (isIOSDevice()) {
      const meta = document.querySelector('meta[name="viewport"]') as HTMLMetaElement;
      if (meta) {
        meta.content = 'width=device-width, initial-scale=1, maximum-scale=1.0';
      }
    }
  }, [initializeAccountStatusStore]);

  useEffect(() => {
    if (shouldRefetchAccountStatus) {
      reInitialize();
      Storage.setRefetchAccountStatus(false);
    }
  }, [reInitialize, shouldRefetchAccountStatus]);

  // TODO: remove this once the issue is fixed
  // Hide ResizeObserver loop completed with undelivered notifications error
  // Hide dev error
  useEffect(() => {
    function hideError(e: { message: string }) {
      if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div',
        );
        const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    }

    window.addEventListener('error', hideError);
    return () => {
      window.addEventListener('error', hideError);
    };
  }, []);

  const handleError = (err: Error) => {
    logException(err.message);
    // eslint-disable-next-line no-console
    console.error({ err });
    Storage.set(
      StorageKeys.GLOBAL_ERROR,
      'We encountered an issue and the application had to be reloaded.',
    );

    window.location.href = lastVisitedTopPage;
  };

  return (
    <ErrorBoundary fallback={<div>Something went wrong</div>} onError={handleError}>
      <ThemeProvider theme={theme}>
        <Router>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <SnackbarProvider>
                <CommonLayout>
                  <RouteTracker />
                  <Routes>
                    <Route element={<RouteGuard />}>
                      {publicRoutes.map((route) => (
                        <Route key={route.path} path={route.path} element={route.element}>
                          {route.children &&
                            route.children.map((child) => (
                              <Route key={child.path} path={child.path} element={child.element} />
                            ))}
                        </Route>
                      ))}
                      {protectedRoutes.map((route) => (
                        <React.Fragment key={route.path}>
                          <Route key={route.path} path={route.path} element={route.element}>
                            {route.children &&
                              route.children.map((child) => (
                                <Route key={child.path} path={child.path} element={child.element} />
                              ))}
                          </Route>
                        </React.Fragment>
                      ))}
                    </Route>
                  </Routes>
                </CommonLayout>
              </SnackbarProvider>
            </AuthProvider>
          </QueryClientProvider>
        </Router>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default App;
