import type { FC } from 'react';

import { Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useToggle } from '@uidotdev/usehooks';

import Modal from '../../../../components/common/Modal/Modal';
import ModalDetail from '../../../../components/common/Modal/ModalDetail';
import type { SubscriptionPlan } from '../../../../services/billing';
import { BillingKeys, PlanTypes, getSubcriptionPlans } from '../../../../services/billing';
import DowngradeSubscriptionContent from './DowngradeSubscriptionContent';
import SubscriptionPlanCard from './SubscriptionPlanCard';
import { subscriptionPlansStatic } from './subscriptionPlansStatic';

interface ManageSubscriptionModalProps {
  isOpen: boolean;
  subscription?: SubscriptionPlan;
  onClose: () => void;
  onUpdateConfirm?: (price_id: string) => void;
  onDowngradeConfirm?: (reason: string) => void;
}

const ManageSubscriptionModal: FC<ManageSubscriptionModalProps> = ({
  isOpen,
  subscription,
  onClose,
  onUpdateConfirm,
  onDowngradeConfirm,
}) => {
  const [showDowngradeContent, toggleShowDowngradeContent] = useToggle(false);

  //   get subscription plans from billing service
  const { data: subscriptionPlans } = useQuery({
    queryKey: [BillingKeys.SUBSCRIPTION_PLANS],
    queryFn: getSubcriptionPlans,
  });

  const onUpdateConfirmClick = () => {
    if (onUpdateConfirm && subscriptionPlans) {
      const proPlanPriceId = subscriptionPlans?.find(
        (plan) => plan.name === PlanTypes.PRO,
      )?.price_id;

      if (proPlanPriceId) {
        onUpdateConfirm(proPlanPriceId);
      }
    }
  };

  const onDowngradeClick = () => {
    toggleShowDowngradeContent(true);
  };

  const onCloseEvent = () => {
    toggleShowDowngradeContent(false);
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={onCloseEvent}
      contentSx={{
        width: 'max-content',
      }}
    >
      <ModalDetail
        title={
          !showDowngradeContent
            ? 'Manage Subscription'
            : 'Are you sure you want to cancel your subscription?'
        }
        subtitle={
          !showDowngradeContent ? 'Unlock unlimited scans, job match scores & more...' : undefined
        }
        onClose={!showDowngradeContent ? onCloseEvent : undefined}
      >
        <Stack
          sx={{
            flexDirection: 'row',
            gap: 2,
            pb: 0.2,
            transition: 'all 0.3s ease',
          }}
        >
          {!showDowngradeContent &&
            subscriptionPlansStatic.map((plan) => (
              <SubscriptionPlanCard
                key={plan.id}
                plan={plan}
                subscription={subscription}
                onUpdateConfirmClick={onUpdateConfirmClick}
                onDowngradeClick={onDowngradeClick}
              />
            ))}

          {showDowngradeContent && (
            <DowngradeSubscriptionContent
              onback={() => toggleShowDowngradeContent(false)}
              onDowngradeConfirm={(reason: string) => {
                if (onDowngradeConfirm) {
                  onCloseEvent();
                  onDowngradeConfirm(reason);
                }
              }}
            />
          )}
        </Stack>
      </ModalDetail>
    </Modal>
  );
};

export default ManageSubscriptionModal;
