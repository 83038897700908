import { Stack, Typography, useTheme } from '@mui/material';

import { useAuth } from '../../../contexts/auth';
import { PlanTypes } from '../../../services/billing';
import Button from '../../common/Button';
import Card from '../../common/Card';
import Icon from '../../common/Icon';

interface UpgradePlanCardProps {
  isOpen?: boolean;
  onUpgradeClick?: () => void;
}

/**
 * UpgradePlanCard component displays a card prompting the user to upgrade their plan.
 * It shows different content based on the user's current subscription plan and whether the card is open or not.
 *
 * @component
 * @param {UpgradePlanCardProps} props - The properties for the UpgradePlanCard component.
 * @param {boolean} [props.isOpen=true] - Determines if the upgrade card is open or not.
 * @param {function} props.onUpgradeClick - Callback function to handle the upgrade button click.
 *
 * @example
 * // Usage example:
 * <UpgradePlanCard isOpen={true} onUpgradeClick={() => console.log('Upgrade clicked')} />
 */
const UpgradePlanCard = ({ isOpen = true, onUpgradeClick }: UpgradePlanCardProps) => {
  const theme = useTheme();
  const { user } = useAuth();
  const planName = user?.subscription || PlanTypes.STANDARD;

  const daysLeftInTrial = 14; // TODO: get days left in trial from user object

  return (
    <Stack direction="row" justifyContent="center">
      {isOpen && (
        <Card
          sx={{
            border: 'none',
            borderRadius: '8px',
            backgroundColor: 'background.mediumLight',
          }}
        >
          <Stack gap={1}>
            <Typography variant="label2">
              {planName === PlanTypes.STANDARD
                ? 'Upgrade to PRO'
                : `${daysLeftInTrial} days free trial left`}
            </Typography>
            <Typography
              variant="body3"
              sx={{
                mb: 1,
                whiteSpace: 'normal',
                wordBreak: 'break-word',
              }}
            >
              Unlimited scans, job match scores & more
            </Typography>
            <Button variant="outlined" onClick={onUpgradeClick}>
              <Icon className="fi fi-rr-progress-upload" fontSize="16px" color="primary.main" />
              Upgrade
            </Button>
          </Stack>
        </Card>
      )}

      {!isOpen && (
        <Button variant="text" onClick={onUpgradeClick}>
          <Icon
            className="fi fi-rr-progress-upload"
            fontSize="20px"
            color={theme.palette.primary.main}
            style={{
              cursor: 'pointer',
            }}
          />
        </Button>
      )}
    </Stack>
  );
};

export default UpgradePlanCard;
