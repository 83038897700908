import { Box, Stack, Typography } from '@mui/material';

import Button from '../../../components/common/Button';
import Card from '../../../components/common/Card';
import Icon from '../../../components/common/Icon';
import LoadingSpinner from '../../../components/common/LoadingSpinner';
import { PlanTypes, type SubscriptionPlan } from '../../../services/billing';
import {
  getNextInvoiceDate,
  getPlanAmountPerMonth,
  getPlanName,
  isFreeSubcriptionPlan,
} from '../../../utils/billingUtils';

const CurrentSubscription = ({
  subscriptionPlan,
  onUpgradeDowngradeClick,
  onManagePaymentMethodClick,
}: {
  subscriptionPlan?: SubscriptionPlan;
  onUpgradeDowngradeClick?: () => void;
  onManagePaymentMethodClick?: () => void;
}) => {
  const { planName, amount, nextInvoiceDate } = subscriptionPlan || {};

  const isFreePlan = isFreeSubcriptionPlan(planName);
  const isProPlan = planName === PlanTypes.PRO;
  const shouldShowPerMonthText = amount && planName !== PlanTypes.STANDARD;

  return (
    <Stack
      sx={{
        gap: 2.5,
        width: '100%',
      }}
    >
      {!subscriptionPlan && <LoadingSpinner />}
      <Typography variant="h3">Current Subscription</Typography>
      <Card elevation={3} sx={{ borderRadius: '12px' }}>
        <Stack
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: '1.5rem',
          }}
        >
          <Stack>
            <Typography variant="body2">{getPlanName(planName)}</Typography>
            <Stack direction="row" gap={1}>
              <Typography variant="h2">
                {getPlanAmountPerMonth(amount)}
                {shouldShowPerMonthText && (
                  <Typography variant="label2" color="text.secondary">
                    {' '}
                    per month
                  </Typography>
                )}
              </Typography>
            </Stack>
            {!isFreePlan && (
              <Typography variant="body3" color="text.secondary">
                {getNextInvoiceDate(nextInvoiceDate)}
              </Typography>
            )}
          </Stack>
          <Stack justifyContent="center">
            {isProPlan && (
              <Button
                variant="text"
                sx={{
                  color: 'text.primary',
                  ':hover': {
                    backgroundColor: 'background.main',
                  },
                }}
                onClick={onUpgradeDowngradeClick}
              >
                Manage
              </Button>
            )}
            {!isProPlan && (
              <Button variant="contained" onClick={onUpgradeDowngradeClick}>
                <Icon className="fi fi-rr-progress-upload" fontSize="16px" color="common.white" />
                Upgrade
              </Button>
            )}
          </Stack>
        </Stack>
      </Card>

      <Box
        sx={{
          display: 'inline-block',
          width: 'fit-content',
          padding: '8px 12px',
          border: '1px solid',
          borderColor: 'border.light',
          borderRadius: '10px',
          cursor: 'pointer',
          ':hover': {
            backgroundColor: 'background.main',
          },
        }}
        onClick={onManagePaymentMethodClick}
      >
        <Typography
          variant="label2"
          sx={{
            display: 'inline-flex',
            gap: 1,
          }}
        >
          Manage Payment Method{' '}
          <Icon
            className="fi fi-rr-arrow-up-right"
            style={{
              cursor: 'pointer',
            }}
          />{' '}
        </Typography>
      </Box>
    </Stack>
  );
};

export default CurrentSubscription;
