import { PlanTypes } from '../services/billing';

/**
 * Determines if the given subscription plan type is a free plan.
 *
 * @param {PlanTypes | undefined} planType - The type of the subscription plan.
 * @returns {boolean} - Returns `true` if the plan is a free plan or if the plan type is undefined, otherwise returns `false`.
 */
export const isFreeSubcriptionPlan = (planType: PlanTypes | undefined) => {
  if (planType === undefined) {
    return true;
  }

  return planType === PlanTypes.STANDARD;
};

/**
 * Returns the name of the plan based on the provided plan type.
 *
 * @param {PlanTypes} planType - The type of the plan.
 * @returns {string} The name of the plan.
 */
export const getPlanName = (planType: PlanTypes | string | undefined) => {
  switch (planType) {
    case PlanTypes.STANDARD:
      return 'Free';
    case PlanTypes.TRIAL:
      return 'Trial';
    case PlanTypes.PRO:
      return 'Pro';
    default:
      return 'Free';
  }
};

/**
 * Formats the given amount as a string representing the monthly plan amount.
 *
 * @param {string | undefined} amount - The amount to be formatted. If undefined, defaults to '$0 Forever'.
 * @returns {string} The formatted amount string.
 */
export const getPlanAmountPerMonth = (amount: string | undefined) => {
  if (amount) {
    return `$${amount}`;
  }
  return '$0 Forever';
};

/**
 * Formats the next invoice date into a human-readable string.
 *
 * @param nextInvoiceDate - The date of the next invoice. If undefined, a default message is returned.
 * @returns A string indicating the next invoice date or a message stating that the next invoice date is not available.
 */
export const getNextInvoiceDate = (nextInvoiceDate: Date | undefined): string => {
  if (!nextInvoiceDate) {
    return 'Next invoice date is not available';
  }

  const dateToFormat =
    typeof nextInvoiceDate === 'string' ? new Date(nextInvoiceDate) : nextInvoiceDate;

  const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short', year: 'numeric' };
  const formattedDate = dateToFormat.toLocaleDateString('en-US', options);
  return `Next invoice is on ${formattedDate}`;
};

/**
 * Formats a given date into a human-readable string in the format "MMM DD, YYYY".
 *
 * @param date - The date to format, which can be a Date object or a string representing a date.
 * @returns A formatted date string in the "MMM DD, YYYY" format.
 */
export const formatBillingDate = (date: Date | string) => {
  const dateToFormat = typeof date === 'string' ? new Date(date) : date;
  const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short', year: 'numeric' };
  return dateToFormat.toLocaleDateString('en-US', options);
};
