import { useLocation, useNavigate } from 'react-router-dom';

import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer as MuiDrawer,
  Stack,
  Typography,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { ReactComponent as MenuOpenIcon } from '../../../assets/icons/fi-rr-menu-open.svg';
import { useAuth } from '../../../contexts/auth';
import RoutePaths from '../../../routes/RoutePaths';
import { PlanTypes } from '../../../services/billing';
import { LoggerEvents, logEvent } from '../../../services/log';
import { useSideBarStore } from '../../../stores/SideBarStore';
import Button from '../../common/Button';
import Icon from '../../common/Icon';
import Logo from '../../common/Logo';
import { drawerWidth, feedbackUrl } from '../../common/constants';
import UpgradePlanCard from './UpgradePlanCard';

const sidebarItems = [
  {
    name: 'Job Matches',
    icon: 'fi fi-rr-exchange',
    path: RoutePaths.JOBMATCHES,
  },
  {
    name: 'Jobs',
    icon: 'fi fi-rr-briefcase',
    path: RoutePaths.JOBS,
  },
  {
    name: 'Resumes',
    icon: 'fi fi-rr-document',
    path: RoutePaths.RESUMES,
  },
];

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer)(() => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
}));

const MobileSideBar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { logout, user } = useAuth();
  const subscription = user?.subscription;

  const isLinkActive = (path: string) => location.pathname.includes(path);

  const { isOpen, toggle: toggleSideBarDrawer, shouldHideSideBar } = useSideBarStore();

  const navigateToPage = (path: string) => {
    toggleSideBarDrawer();
    navigate(path);
  };

  const handleClickFeeback = () => {
    toggleSideBarDrawer();
    logEvent(LoggerEvents.FEEDBACK_CLICK);
    window.open(feedbackUrl, '_blank');
  };

  const handleClickSettings = () => {
    navigateToPage('/settings');
  };

  const handleClickLogout = (): void => {
    toggleSideBarDrawer();
    logout();
  };

  const onUpgradeClick = () => {
    toggleSideBarDrawer();
    // TODO: open the mobile swipeable upgrade modal
    navigate(`${RoutePaths.BILLING}?action=upgrade`);
  };

  return (
    <Drawer
      variant="temporary"
      anchor="left"
      open={isOpen}
      onClose={toggleSideBarDrawer}
      sx={{ '&.MuiDrawer-root>.MuiPaper-root': { height: '100%', borderRadius: 0 } }}
    >
      <DrawerHeader>
        <Stack sx={{ flexDirection: 'row', paddingX: 1.5, paddingTop: 1 }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleSideBarDrawer}
            edge="start"
            sx={{
              padding: 1,
              ...(shouldHideSideBar && { display: 'none' }),
            }}
          >
            {isOpen ? (
              <MenuOpenIcon />
            ) : (
              <Icon
                className="fi fi-rr-menu-burger"
                fontSize="20px"
                color={theme.palette.text.secondary}
              />
            )}
          </IconButton>
          <Logo width="110px" height="auto" />
        </Stack>
      </DrawerHeader>
      <Stack
        sx={{ justifyContent: 'space-between', height: '100%', marginTop: 3, marginBottom: 1 }}
      >
        <List sx={{ paddingY: 0, paddingX: 1.5 }}>
          {sidebarItems.map((item) => (
            <ListItem
              key={item.name}
              disablePadding
              sx={{
                display: 'block',
                mb: '16px',
              }}
              onClick={() => navigateToPage(item.path)}
            >
              <ListItemButton
                sx={{
                  minHeight: 36,
                  justifyContent: 'center',
                  backgroundColor: isLinkActive(item.path) ? 'primary.light' : 'transparent',
                  borderRadius: '8px',
                  padding: '8px',
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    maxHeight: '20px',
                    mr: '10px',
                    justifyContent: 'center',
                    backgroundColor: isLinkActive(item.path) ? 'primary.light' : 'transparent',
                    borderRadius: '8px',
                  }}
                >
                  {item.icon && (
                    <Icon
                      className={item.icon}
                      color={
                        isLinkActive(item.path)
                          ? theme.palette.primary.main
                          : theme.palette.text.secondary
                      }
                      fontSize="20px"
                    />
                  )}
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ variant: isLinkActive(item.path) ? 'label2' : 'body3' }}
                  primary={item.name}
                  sx={{
                    opacity: 1,
                    color: isLinkActive(item.path) ? 'primary.main' : 'text.primary',
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Stack gap={2}>
          {(subscription === PlanTypes.STANDARD || subscription === PlanTypes.TRIAL) && (
            <UpgradePlanCard isOpen={isOpen} onUpgradeClick={onUpgradeClick} />
          )}
          <Button
            variant="text"
            onClick={handleClickFeeback}
            sx={{
              marginX: 1.5,
              marginY: 0.5,
              minHeight: 36,
              minWidth: 0,
              justifyContent: 'initial',
              padding: '8px 12px',
              color: 'text.primary',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
            }}
          >
            <Icon
              className="fi fi-rr-shield-interrogation"
              color={theme.palette.text.secondary}
              fontSize="20px"
            />
            <Typography variant="body3">Feedback</Typography>
          </Button>
          <Divider />
          <Stack gap={1}>
            <Button
              variant="text"
              onClick={handleClickSettings}
              sx={{
                marginX: 1.5,
                marginY: 0.5,
                minHeight: 36,
                minWidth: 0,
                justifyContent: 'initial',
                padding: '8px 12px',
                color: 'text.primary',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            >
              <Icon
                className="fi fi-rr-settings"
                color={theme.palette.text.secondary}
                fontSize="20px"
              />
              <Typography variant="body3">Settings</Typography>
            </Button>
            <Button
              variant="text"
              onClick={handleClickLogout}
              sx={{
                marginX: 1.5,
                marginY: 0.5,
                minHeight: 36,
                minWidth: 0,
                justifyContent: 'initial',
                padding: '8px 12px',
                color: 'text.primary',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            >
              <Icon
                className="fi fi-rr-exit"
                color={theme.palette.text.secondary}
                fontSize="20px"
              />
              <Typography variant="body3">Sign out</Typography>
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Drawer>
  );
};

export default MobileSideBar;
