import { Stack, Typography, useTheme } from '@mui/material';

import Card from '../../../../components/common/Card';
import Icon from '../../../../components/common/Icon';

/**
 * NoReceiptsContent component displays a message indicating that there are no receipts available.
 * It uses Material-UI components such as Card, Stack, Icon, and Typography to create a styled message.
 *
 * @returns {JSX.Element} A React component that renders a card with a message and an icon.
 */
const NoReceiptsContent = () => {
  const theme = useTheme();

  return (
    <Card elevation={3} sx={{ borderRadius: '12px' }}>
      <Stack
        sx={{
          gap: '1.5rem',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          paddingTop: '4rem',
          paddingBottom: '4rem',
        }}
      >
        <Icon
          className="fi fi-rr-receipt"
          fontSize="48px"
          style={{
            color: theme.palette.text.tertiary,
          }}
        />
        <Typography variant="body3">Your receipts will show up here</Typography>
      </Stack>
    </Card>
  );
};

export default NoReceiptsContent;
